/* =================== */
/* General Stylization */
/* =================== */
/* estes ajustes requerem revisão de responsivo !! */

/* força a cor de fundo da página */
body {
  background: #fbfbfb !important;
}

/* Scrollbar */
/* altera globalmente o scrollbar dos elementos */
*::-webkit-scrollbar {
  width: 0.7em;
}

*::-webkit-scrollbar-track {
  border-radius: 30px;
  background-color: #b2b2b23a;
}

*::-webkit-scrollbar-thumb {
  background-color: #b2b2b2ea;
  border-radius: 30px;
  border: 0.2em solid transparent;
  -webkit-background-clip: content-box;
  background-clip: content-box;
}

*::-webkit-scrollbar-thumb:hover{
  background-color: #a3a3a3;
}

*::-webkit-scrollbar-thumb:active {
  border: 0.15em solid transparent;
  -webkit-background-clip: content-box;
  background-clip: content-box;
}

/* temporariamente, esconde o título da lista de seleções (blocos) */
.MuiPaper-elevation3 .MuiCard-root h6 {
  display: none;
}

/* força a largura do card para 100% da tela */
.MuiCard-root {
  min-width: 100%;
}

/* Tabelas */
/* adicione a classe "custom-table" na tabela para que a estilização seja aplicada */
/* cor do topo */
.custom-table .MuiDataGrid-columnHeaderWrapper {
  background: #f5f5f5;
}
/* tipo/peso da fonte */
.custom-table .MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
}
/* alinha o conteúdo da coluna "Ações" no centro */
.custom-table
  .MuiDataGrid-columnHeader[data-field='id']
  .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
}
/* esconde o ícone de ordenação da coluna "Ações" */
.custom-table
  .MuiDataGrid-columnHeader[data-field='id']
  .MuiDataGrid-iconButtonContainer {
  display: none;
}
/* esconde o a barra de separação (pipe) da coluna de Ações */
.custom-table
  .MuiDataGrid-columnHeader[data-field='id']
  .MuiDataGrid-columnSeparator {
  display: none !important;
}

/* Menu Lateral */
/* define a cor de fundo do menu lateral */
.MuiCollapse-wrapperInner .MuiList-root {
  background: #f5f5f5;
}
/* ajusta espaçamento na esquerda do menu */
.MuiCollapse-wrapperInner .MuiList-root .MuiListItem-gutters {
  padding-left: 25px;
}
/* define o espaçamento entre ícone e texto do submenu */
.MuiCollapse-wrapperInner .MuiList-root .MuiListItemIcon-root {
  min-width: 26px;
}
/* define o tamanho do ícone do submenu */
.MuiCollapse-wrapperInner .MuiList-root .MuiSvgIcon-root {
  width: 20px;
}
/* define a cor de ícones desabilitados no menu lateral */
.MuiCollapse-wrapperInner .MuiList-root .Mui-disabled .MuiSvgIcon-root {
  color: rgb(0 0 0 / 40%);
}
/* define o tamanho da fonte do submenu */
.MuiCollapse-wrapperInner .MuiList-root .MuiTypography-body1 {
  font-size: 13px;
}

/* Barra do título */
/* adicione a classe "title-bar" na barra de título para aplicar a estilização */
/* definição básica da barra azul de títulos */
.title-bar {
  background: #312783;
  margin-left: -24px !important;
  padding-left: 12px;
  position: relative;
  width: calc(100% + 24px) !important;
  box-shadow: 0 3px 3px #dddddd;
  height: 117px;
  padding-top: 63px;
  top: -24px;
}
/* definições da fonte do título */
.title-bar h1 {
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  display: inline;
  color: #ffffff;
}
/* definições do ícone apresentado na barra de título */
.title-bar svg {
  fill: #f39200;
  vertical-align: bottom;
  display: inline-block;
  margin-right: 10px;
  min-height: 24px;
}
/* adicione também a classe "texture" para deixar a barra no formato azul com textura - className="title-bar texture" */
.texture {
  background-color: transparent;
  background-image: url(../assets/images/background_header.jpg);
  background-repeat: no-repeat;
  background-position: center 28%;
}
.texture h1 {
  color: #ffffff;
}

/* Rodapé */
/* adicione a classe "fixed-footer" no container da página para forçar a fixação do rodapé abaixo da página */
.fixed-footer {
  min-height: 100vh;
  padding-top: 24px;
  position: relative;
}

/* Labels */
/* remove a borda que o Textfield adiciona no final das labels */
fieldset label span {
  padding-right: 15px !important;
}

/* Inputs */
/* Remove a cor de fundo que o chrome adiciona em campos com preenchimento automático */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/* força a cor de fonte nos campos com preenchimento automático do chrome*/
input:-webkit-autofill {
  -webkit-text-fill-color: #312783 !important;
}

/* muda a cor do ícone "checked" exibido em algumas tabelas (coluna "Ativo") */
.MuiDataGrid-root .MuiDataGrid-booleanCell[data-value='true'] {
  color: green !important;
}

/* força fundo transparente ao clicar no campo select */
.MuiSelect-select:focus,
.MuiSelect-selectMenu:focus {
  background-color: transparent !important;
}

/* Imagem "Q" exibida na página inicial */
.image-background {
  background: #fafafa url(../assets/images/icon_Q.svg) center center no-repeat;
  background-size: contain;
  width: 400px;
  height: 320px;
  margin: 5rem auto 0;
  display: table;
  background-blend-mode: exclusion;
  filter: opacity(0.045);
}

/* Autocomplete  */
/* Ajusta espaçamento do lado direito entre o texto do
   input e os botões internos (clear e open dropbox) */
.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  padding-right: 62px !important;
}

/* Bradcrumbs */
/* define estilização da barra de breadcrumbs */
.breadcrumbs-bar {
  background: #fdfdfd;
  border-bottom: 1px solid #e8e8e8;
  position: absolute;
  z-index: 0;
  left: -28px;
  height: 43px;
  min-width: calc(100% + 28px);
  top: 117px;
  padding-left: 4px;
  box-shadow: inset 2px 3px 4px rgb(0 0 0 / 38%);
}
/* diminui espaçamento do botão "voltar" na lateral direita da barra de breadcrumbs */
.breadcrumbs-bar .MuiIconButton-root {
  padding: 8px;
}

/* remove espaço lateral do ícone dos campos tipo data */
.MuiOutlinedInput-adornedEnd {
  padding-right: 0 !important;
}

.Mui-disabled label,
.Mui-disabled .MuiFormLabel-root {
  color: rgb(49 39 130 / 61%) !important;
}

.Mui-error {
  color: #f44336 !important;
}
.Mui-error.Mui-focused fieldset {
  border-color: #f44336 !important;
}
.Mui-error .MuiIconButton-root svg {
  fill: #f44336 !important;
}

.MuiCheckbox-root {
  margin: -9px 0 !important;
}

/* ============================ */
/* Ajustes Responsivos          */
/* ============================ */

